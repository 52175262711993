import React from "react";
import appImages from "../../assets";
import "../../styles/componentSass/header/_dashboardHeader.scss";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Logout } from "@mui/icons-material";
const DashboardHeader = (props) => {
  const { openDashMenu, toggleMenu } = props;

  return (
    <div className="dashboardTopMenuCont">
      <div className="topNavCont1">
        <img className="dashHeaderLogo" src={appImages.appImages.logo} />
        <MenuOpenIcon
          className="menuIconDash"
          onClick={() => {
            toggleMenu();
          }}
        />
      </div>
      <div className="topNavCont2">
        <h3 className="dashRouteName">Dashboard</h3> &nbsp;&nbsp;|{" "}
        <h3 className="dashSubRouteName">&nbsp;&nbsp;SubRoute&nbsp;&nbsp;</h3>{" "}
        &nbsp;&nbsp;|
        <h3 className="dashEndRouteName">&nbsp;&nbsp;Route</h3>
      </div>
      <div className="topNavCont3">
        <div className="dashChatNavCont">
          <h3 className="chatTxtDash">Chats</h3>
          <img
            className="receiverImgAvatar"
            alt="chatProfile"
            src="https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"
          />
          <img
            className="receiverImgAvatar"
            alt="chatProfile"
            src="https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"
          />
          <img
            className="receiverImgAvatar"
            alt="chatProfile"
            src="https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"
          />
        </div>
        <div
          className="logoutDashBtn"
          onClick={() => {
            // setViewChat(false);
          }}
        >
          <Logout className="headerDashLogoutIcon" />
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
