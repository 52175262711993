import React from "react";
import appImages from "../../assets";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import appColors from "../../utils/themes/colorThemes";
// import FontAwesome from "react-fontawesome";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { KeyboardArrowDown } from "@mui/icons-material";

const HeaderWindow = (props) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const subServices = [
    {
      name: "HCI Data center",
      navigationRoute: "/HCI",
    },
    {
      name: "Workload migration",
      navigationRoute: "/WorkLoadMigration",
    },
    {
      name: "Disaster Recovery",
      navigationRoute: "/DisasterRecovery",
    },
    {
      name: "Cloud Automation",
      navigationRoute: "/CloudAutomation",
    },
    {
      name: "App Modernization",
      navigationRoute: "/AppModernization",
    },

    {
      name: "Web development",
      navigationRoute: "/WebDevelopment",
    },
  ];
  return (
    <div className="windowHeaderContainer" id="windowHeader">
      <div className="contactHeaderHomeCont">
        <div
          className="socialHeaderInfoCont centerGrid80"
          style={{ marginTop: "0px" }}
        >
          <div className="headInfoCont">
            <LocalPostOfficeIcon fontSize="10px" className="headerInfoIcon" />
            <p>
              &nbsp;&nbsp;&nbsp;contactus@avancerpi.com &nbsp;&nbsp;&nbsp;|
              &nbsp;&nbsp;&nbsp;
            </p>
            <LocalPhoneIcon fontSize="10px" className="headerInfoIcon" />
            <p> &nbsp;&nbsp;&nbsp;+91 9902693693 </p>
          </div>
          <div className="headInfoCont">
            <FacebookIcon fontSize="10px" className="headerSocialIcon" />
            <InstagramIcon fontSize="10px" className="headerSocialIcon" />
            <TwitterIcon fontSize="10px" className="headerSocialIcon" />
            <LinkedInIcon fontSize="10px" className="headerSocialIcon" />
          </div>
        </div>
      </div>
      <div className="headerOutWin">
        <div
          className="headerContentsCont centerGrid80"
          style={{ marginTop: "0px" }}
        >
          <div className="container1">
            <img
              src={appImages.appImages.logo}
              className="headerLogoWin"
              alt="avancerpiAppLogo"
            />
          </div>
          <div className="container2">
            <ul className="navbar">
              <li style={{ left: "-20px" }} className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/" || currentRoute === "/home"
                      ? "activeNavColor"
                      : ""
                  }`}
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/About" ? "activeNavColor" : ""
                  }`}
                  to="/About"
                >
                  About
                </Link>
              </li>

              <li className="subMenu navbarChild">
                <Link
                  onClick={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "flex";
                  }}
                  onMouseOver={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "none";
                  }}
                  className={`screenLinks ${
                    currentRoute === "/Services" ||
                    currentRoute === "/DataCenter" ||
                    currentRoute === "/DataCenterSubServices" ||
                    currentRoute === "/WebDev" ||
                    currentRoute === "/AppModernization" ||
                    currentRoute === "/CloudAutomation" ||
                    currentRoute === "/DisasterRecovery" ||
                    currentRoute === "/WorkLoadMigration"
                      ? "activeNavColor"
                      : ""
                  } subServ`}
                  to="/Services"
                >
                  Services
                  <KeyboardArrowDown
                    style={{
                      marginLeft: 5,
                      fontSize: 20,
                      color: appColors.primaryColor,
                    }}
                  />
                </Link>
                <ul className="innerSubList">
                  {subServices.map((subService, i) => {
                    return (
                      <li
                        onMouseOver={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "flex";
                        }}
                        onMouseOut={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "none";
                        }}
                        onClick={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "none";
                        }}
                        key={i}
                        className="navInList"
                      >
                        <Link
                          to={subService.navigationRoute}
                          className="menuHeading"
                        >
                          {subService.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/Career" ? "activeNavColor" : ""
                  }`}
                  to="/Career"
                >
                  Career
                </Link>
              </li>
              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/ContactUs" ? "activeNavColor" : ""
                  }`}
                  to="/Contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="headerLoginBtn">
            <p style={{ color: "white", fontSize: 14 }}>Login</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderWindow;
