import React, { Fragment } from "react";
import appImages from "../../assets";
import "../../styles/layoutSass/home/base.scss";
import "../../styles/layoutSass/about/base.scss";
import appColors from "../../utils/themes/colorThemes";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ArrowRight,
  ChevronRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CommentIcon from "@mui/icons-material/Comment";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import Slider from "./LogoSlider";
import { useState } from "react";

export const AboutUs = () => {
  const clientLogos = [
    appImages.logos.nutanixshort,
    appImages.logos.redhat,
    appImages.logos.vmware,
  ];
  const [id, setId] = useState(0);
  const getId = () => {
    const randId = Math.random().toFixed(2);
    return randId;
  };

  const [blogList, setBlogList] = useState([
    {
      blogName: "Evolving program Manage Office for modern",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "13 June 2021",
      blogComments: 5,
    },
    {
      blogName: "Design Decision with Bea Fietler Unstoppable",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "15 June 2021",
      blogComments: 3,
    },
    {
      blogName: "Pain free Workflow Issue Reporting Resolution",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "16 June 2021",
      blogComments: 15,
    },
  ]);

  const accordianData = [
    {
      heading: "Find and get solutions for your success",
      accordCont: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      heading: "Get right knowledge of your passion",
      accordCont: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      heading: "Find the person sharing the same position as you",
      accordCont: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      heading: "Find right mentor and coach as per your goal and passion ",
      accordCont: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      heading:
        "Find right collaborator and opportunities as per your goal and passion",
      accordCont: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ];

  const [accordIndex, setAccordIndex] = useState(0);
  const certiImages = Object.entries(appImages.certifications);

  const [certiCarInd, setCertiCarInd] = useState({
    startInd: 0,
    noOfEl: 7,
  });
  const [sliderImages, setSliderImages] = useState(
    certiImages.slice(certiCarInd, 7)
  );
  return (
    <div className="mainContentSection">
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"About Us"}</h1>

          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; About
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont ">
        <div className="whoWeHeadCont">
          <p className="whoWeHeadTxt">We have</p>
          <p className="whoWeHeadTxt" style={{ color: appColors.primaryColor }}>
            24 years
          </p>
          <p className="whoWeHeadTxt">experience in our Business</p>
          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContHeadTxt">
            Our solid conviction is that if a business is to be upgraded, it's
            to be streamlined in a full cycle way!
          </p>
          <p className="whoWeContTxt">
            No one rejects, dislikes, or avoids pleasure itself, because it is
            pleasure, but because those who do not know how to pursue pleasure
            rationally encounter consequences that are extremely painful. Nor
            again is there anyone who loves or pursues or desires to obtain pain
            of itself, because it is pain, but because occasionally
            circumstances occur in which toil and pain can procure him some
            great pleasure. To take a trivial example, which of us ever
            undertakes laborious physical exercise, except to obtain some
            advantage from it? But who has any right to find fault with a man
            who chooses to enjoy a pleasure that has no annoying consequences.
          </p>
        </div>
      </div>

      <div className="homeTestimonialsCont aboutAddInfoCont">
        <div className="homeTestimonialsInnCont centerGrid80 aboutAddInfoContContent">
          <div className="homeTestiHeadCont">
            <p
              className="headTxtSmall smallBoldTitles"
              style={{ textAlign: "left" }}
            >
              <b>About Avancer </b>
            </p>
            <p
              className="whoWeHeadTxt"
              style={{ marginTop: 10, color: appColors.whiteColor }}
            >
              Additional Information
            </p>
            <p className="whoWeContTxt" style={{ color: appColors.whiteColor }}>
              No one rejects, dislikes, or avoids pleasure itself, because it is
              pleasure, but because those who do not know how to pursue pleasure
              rationally encounter consequences that are extremely painful. Nor
              again is there anyone who loves or pursues or desires to obtain
              pain of itself, because it is pain, but because occasionally
              circumstances occur in which toil and pain can procure him some
              great pleasure. To take a trivial example, which of us ever
              undertakes laborious physical exercise, except to obtain some
              advantage from it? But who has any right to find fault with a man
              who chooses to enjoy a pleasure that has no annoying consequences.
            </p>

            <p
              className="whoWeHeadTxt"
              style={{
                marginTop: 10,
                color: appColors.whiteColor,
                fontSize: 28,
              }}
            >
              Additional Information
            </p>
            <p className="whoWeContTxt" style={{ color: appColors.whiteColor }}>
              No one rejects, dislikes, or avoids pleasure itself, because it is
              pleasure, but because those who do not know how to pursue pleasure
              rationally encounter consequences that are extremely painful. Nor
              again is there anyone who loves or pursues or desires to obtain
              pain of itself.
            </p>
          </div>

          <div className="homeTestiContCont aboutInfosCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p>Find and get solutions for your success.</p>
                    <KeyboardArrowDown className="aboutAccArrow" />
                  </div>
                  {accordIndex == i && (
                    <div className="accordContCont">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="centerGrid80 aboutWhoWeAreCont "
        style={{ marginBottom: 25 }}
      >
        <div className="whoWeHeadCont">
          <p
            className="headTxtSmall smallBoldTitles"
            style={{ textAlign: "left", marginTop: 25 }}
          >
            <b>About Avancer </b>
          </p>

          <p className="whoWeHeadTxt">
            Our mission is to make your business better through technology
          </p>

          <div className="whoWeUnderline" />
          <p className="whoWeContTxt" style={{ marginTop: 20 }}>
            No one rejects, dislikes, or avoids pleasure itself, because it is
            pleasure, but because those who do not know how to pursue pleasure
            rationally encounter consequences that are extremely painful. Nor
            again is there anyone who loves or pursues or desires to obtain pain
            of itself.
          </p>
        </div>
        <div className="aboutCertBannerCont">
          <img
            alt="avancerCertifications"
            src={appImages.aboutUsImgs.certificationBanner}
            className="aboutCertBanner"
          />
        </div>
      </div>

      <div className="centerGrid60 aboutCertCarCont" key={getId()}>
        <ArrowBackIos
          fontSize="small"
          className="certiCarIcon"
          onClick={() => {
            if (Math.sign(certiCarInd.startInd) !== -1) {
              let certImgs = [...certiImages];
              certImgs = certImgs.splice(
                certiCarInd.startInd - 1,
                certiCarInd.noOfEl
              );

              if (certImgs.length === certiCarInd.noOfEl) {
                setId(getId());
                document.getElementsByClassName(
                  "certiCarIcon"
                )[1].style.opacity = 1;
                setSliderImages([...certImgs]);
                setCertiCarInd({
                  ...certiCarInd,
                  startInd: certiCarInd.startInd - 1,
                });
              } else {
                document.getElementsByClassName(
                  "certiCarIcon"
                )[0].style.opacity = 0;
              }
            } else {
              document.getElementsByClassName(
                "certiCarIcon"
              )[0].style.opacity = 0;
            }
          }}
        />

        {[...sliderImages].map((img, i) => {
          return (
            <img
              key={i}
              alt="avancerCertificates"
              src={img[1]}
              className="aboutSingleCertiImg"
            />
          );
        })}
        <ArrowForwardIos
          fontSize="small"
          className="certiCarIcon"
          onClick={() => {
            let certImgs = [...certiImages];
            certImgs = certImgs.splice(
              certiCarInd.startInd + 1,
              certiCarInd.noOfEl
            );

            if (certImgs.length === certiCarInd.noOfEl) {
              setId(getId());

              document.getElementsByClassName(
                "certiCarIcon"
              )[0].style.opacity = 1;
              setSliderImages([...certImgs]);
              setCertiCarInd({
                ...certiCarInd,
                startInd: certiCarInd.startInd + 1,
              });
            } else {
              document.getElementsByClassName(
                "certiCarIcon"
              )[1].style.opacity = 0;
            }
          }}
        />
      </div>
      <div className="homeBlogsCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR BLOGS </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Trending Business Consulting
          <br />
          Articles {"&"} Tips
        </p>
        <div className="blogCardsContHome centerGrid60">
          {blogList.map((blog, i) => {
            return (
              <div className="homeBlogCard" key={i}>
                <img
                  src="https://www.jobboardfinder.com/news/wp-content/uploads/2021/07/team.jpg"
                  className="blogCardImageHome"
                  alt="blogImg"
                />
                <p className="blogTitleHome">{blog.blogName}</p>
                <p className="homeBlogDesc">{blog.blogDesc}</p>
                <div className="homeBlogTimeComCont">
                  <EventNoteIcon
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 3.5 }}>
                    {blog.blogDate}
                  </p>
                  <CommentIcon
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 2.5 }}>
                    {blog.blogComments}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="clientSliderContHome">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR CLIENTS </b>
        </p>
        <p
          style={{ marginTop: 10 }}
          className="aboutHeadingHome textAlignCenter"
        >
          Committed to Helping
          <br />
          Our Clients Succeed
        </p>

        <div className="customMarquee">
          <div className="marqueeInner">
            <Slider data={clientLogos} />
            {/* <marquee>
              {clientLogos.map((client, i) => {
                return (
                  <img
                    key={i}
                    className="logoImg"
                    src={client}
                    alt="avancerClients"
                  />
                );
              })}
            </marquee> */}
          </div>
        </div>
      </div>
      <div className="homeFormsCont">
        <p className="headTxtSmall smallBoldTitles" style={{ marginTop: -100 }}>
          <b>JOIN US</b>
        </p>

        <div className="blogCardsContHome centerGrid60">
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Let's Ready to Get
                <br /> Work Together
              </p>
              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">Join Now</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Request to call back <br />
                We Will Call Back
              </p>

              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">Get a quote</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Build a Better Career <br />
                With Avancer
              </p>
              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">View jobs</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
