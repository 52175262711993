import React from "react";
import { Route, Routes } from "react-router";
import SiteLayout from "../../layouts/SiteLayout";
import Login from "../../screens/mainScreens/Login";
import Home from "../../screens/mainScreens/Home";
import AppRoute from "../AppRoute";
import NoScreen from "../../screens/mainScreens/NoScreen";
import AboutUs from "../../screens/mainScreens/AboutUs";
import HCI from "../../screens/mainScreens/services/HCI";
import ContactUs from "../../screens/mainScreens/ContactUs";
import WorkLoadMigration from "../../screens/mainScreens/services/WorkLoadMigration";
import DisRec from "../../screens/mainScreens/services/DisRec";
import CloudAutomation from "../../screens/mainScreens/services/CloudAutomation";
import AppModern from "../../screens/mainScreens/services/AppModern";
import WebDev from "../../screens/mainScreens/services/WebDev";
import Career from "../../screens/mainScreens/Career";
import ScrollToTop from "../../components/ScrollToTop";

const SiteNav = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route
          exact
          path="/"
          element={<AppRoute component={Home} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/home"
          element={<AppRoute component={Home} layout={SiteLayout} />}
        ></Route>

        <Route
          exact
          path="/login"
          element={<AppRoute component={Login} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/about"
          element={<AppRoute component={AboutUs} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/HCI"
          element={<AppRoute component={HCI} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/WorkLoadMigration"
          element={
            <AppRoute component={WorkLoadMigration} layout={SiteLayout} />
          }
        ></Route>
        <Route
          exact
          path="/DisasterRecovery"
          element={<AppRoute component={DisRec} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/CloudAutomation"
          element={<AppRoute component={CloudAutomation} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/AppModernization"
          element={<AppRoute component={AppModern} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/WebDevelopment"
          element={<AppRoute component={WebDev} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/Career"
          element={<AppRoute component={Career} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="/Contact"
          element={<AppRoute component={ContactUs} layout={SiteLayout} />}
        ></Route>
        <Route
          exact
          path="*"
          element={<AppRoute component={NoScreen} layout={SiteLayout} />}
        ></Route>
      </Routes>
    </ScrollToTop>
  );
};

export default SiteNav;
