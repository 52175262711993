import React from "react";
import appImages from "../../assets";
import "../../styles/layoutSass/home/base.scss";
import "../../styles/layoutSass/about/base.scss";
import "../../styles/layoutSass/career/base.scss";
import appColors from "../../utils/themes/colorThemes";
import {
  AttachFile,
  CheckCircle,
  ChevronRight,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import formValidators from "../../utils/commonFunctions/formValidators";
import Toast from "../../components/Toast";
import Select from "react-select";
import { getJobsList } from "../../redux/actions/app.actions";
import { connect } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoDataFound from "../../components/NoDataFound";
import { useRef } from "react";
import { applyJob } from "../../redux/actions/form.actions";
const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  position: "",
  message: "",
  resume: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  position: false,
  message: false,
  resume: false,
};

export const Career = (props) => {
  const [careerDetails, setCareerDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const setDetails = (value, key) => {
    setCareerDetails({ ...careerDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();

    if (requestData) {
      setLoading(true);
      console.log(requestData, "requestDatarequestData");
      if (requestData) {
        for (var value of requestData) {
          console.log(value);
        }
      }
      props.applyJob(
        requestData,
        (res) => {
          const response = res;
          if (Number(response.statusCode) === 200) {
            setResMsg({
              icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
              msg: response.message,
              bgColor: appColors.greenOverlay,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 3000);
            setValdiationErrors(initialErrors);
            setCareerDetails(initialDetails);
            setrefresh(!refresh);
            setLoading(false);
          } else {
            setResMsg({
              icon: (
                <WarningAmberOutlined style={{ color: appColors.whiteColor }} />
              ),
              msg: "Oops! Something went wrong!",
              bgColor: appColors.red,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 2000);
            setLoading(false);
          }
        },
        false,
        false
      );
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(careerDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("candidate_name", careerDetails.name);
    }
    if (formValidators.checkEmpty(careerDetails.resume)) {
      errors.resume = "Choose resume";
      formData = undefined;
    } else {
      errors.resume = false;
      formData?.append("candidate_resume", careerDetails.resume);
    }
    if (formValidators.checkEmpty(careerDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(careerDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", careerDetails.mobile);
    }
    if (formValidators.checkEmpty(careerDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(careerDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", careerDetails.email);
    }

    if (formValidators.checkEmpty(careerDetails.position)) {
      errors.position = "Enter position";
      formData = undefined;
    } else {
      errors.position = false;
      formData?.append("job_id", careerDetails.position?.job_id);
    }
    if (formValidators.checkEmpty(careerDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(careerDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", careerDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  const [jobList, setJobsList] = useState(false);
  const handleChange = (selectedOption) => {
    setCareerDetails({ ...careerDetails, position: selectedOption });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? appColors.secondaryColor
        : appColors.secondaryColor,
      padding: 20,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: "#283646a4",
      maxHeight: 30,
      fontSize: 14,
      color: appColors.whiteColor,
    }),
    input: () => ({
      fontSize: 14,
      color: appColors.whiteColor,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color: "white" };
    },
  };

  const nameRef = useRef(null);
  const [refresh, setrefresh] = useState(false);

  React.useEffect(() => {
    let isActive = true;
    getJobsLists();
    return () => {
      isActive = false;
    };
  }, [refresh]);

  const getJobsLists = () => {
    props.getJobsList(
      null,
      (res) => {
        const response = res;

        if (Number(response?.statusCode) === 200) {
          let jobDatas = [];

          response.data.forEach((element) => {
            let singleJobData = { ...element };
            singleJobData.label = element?.job_title;
            singleJobData.value = element?.job_id;
            jobDatas = [...jobDatas, singleJobData];
          });
          setJobsList(jobDatas);
        } else {
          setJobsList([]);
        }
      },
      false,
      false
    );
  };
  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Careers"}</h1>

          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; About
          </p>
        </div>
      </div>
      <div className="centerGrid60 careerInfosCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>Careers </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            marginTop: 20,
            marginBottom: 20,
            color: appColors.blackColor,
          }}
        >
          Why career with us
        </p>
        <div className="careerBoxCont">
          <div className="careerSqr">
            <div className="careerCircle">
              <img alt="avancerImg" src={appImages.icons.consult} />
            </div>
            <p
              style={{ color: appColors.blackColor }}
              className="aboutHeadingHome textAlignCenter tagHead careerCircleTagTxt"
            >
              Friendly Environment
            </p>
            <p className="tagContent">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          <div className="careerSqr">
            <div className="careerCircle">
              <img
                alt="avancerImg"
                style={{
                  marginLeft: 10,
                }}
                src={appImages.icons.design}
              />
            </div>
            <p
              style={{ color: appColors.blackColor }}
              className="aboutHeadingHome textAlignCenter tagHead careerCircleTagTxt"
            >
              Planning
            </p>
            <p className="tagContent">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>

          <div className="careerSqr">
            <div className="careerCircle">
              <img alt="avancerImg" src={appImages.icons.build} />
            </div>
            <p
              style={{ color: appColors.blackColor }}
              className="aboutHeadingHome textAlignCenter tagHead careerCircleTagTxt"
            >
              Execute
            </p>
            <p className="tagContent">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          <div className="careerSqr">
            <div className="careerCircle">
              <img alt="avancerImg" src={appImages.icons.train} />
            </div>
            <p
              style={{ color: appColors.blackColor }}
              className="aboutHeadingHome textAlignCenter tagHead careerCircleTagTxt"
            >
              Deliver
            </p>
            <p className="tagContent">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div>
      </div>
      <div className="homeServicesCont  careerListCont">
        <div className="centerGrid60 careerCardsTitleCont">
          <div className="jobOppTxtBord">
            <p className="jobOppTxt">Job opportunities</p>
          </div>
          <div className="homeAboutLearnMore" style={{ cursor: "none" }}>
            <p className="learnMoreHomeAbt">Discover Avancer</p>
          </div>
        </div>

        {!Boolean(jobList) ? (
          <div className="centerGrid60 jobCardsCont">
            <SkeletonTheme baseColor={appColors.palePrimary}>
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
                className="careerSqrCard"
              />
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
                className="careerSqrCard"
              />
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
                className="careerSqrCard"
              />
            </SkeletonTheme>
          </div>
        ) : Array.isArray(jobList) && jobList.length !== 0 ? (
          <div className="centerGrid60 jobCardsCont">
            {jobList.map((job, i) => {
              return (
                <div className="careerSqrCard" key={i}>
                  <div className="careerInnrSqrCard">
                    <div className="flip-card-front">
                      <p className="jobCardCnt">0{i + 1}.</p>
                      <p className="serviceTitleHome marginLeft0">
                        {job?.job_title}
                      </p>
                      <p className="homeServDesc marginLeft0">
                        {job?.job_description}
                      </p>
                      <div className="jobLocDetCont">
                        <p className="jobLocationHeading">Location : &nbsp; </p>
                        <p className="jobLocationsTxt">{job?.job_location}</p>
                      </div>
                      <div
                        className="homeAboutLearnMore applyBtnJob"
                        onClick={(e) => {
                          e.preventDefault();
                          handleChange(job?.job_id);
                          nameRef?.current?.focus();
                        }}
                      >
                        <p className="learnMoreHomeAbt">Apply Now</p>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="jobLocDetCont flipDet">
                        <p className="jobLocationHeading">
                          Experience : &nbsp;{" "}
                        </p>
                        <p className="jobLocationsTxt">{job?.experience}</p>
                      </div>
                      <div className="jobLocDetCont flipDet">
                        <p className="jobLocationHeading">Skillset : &nbsp; </p>
                        <p className="jobLocationsTxt">{job?.skillset}</p>
                      </div>
                      <div className="jobLocDetCont flipDet">
                        <p className="jobLocationHeading">
                          Qualtification : &nbsp;{" "}
                        </p>
                        <p className="jobLocationsTxt">{job?.qualification}</p>
                      </div>
                      <div
                        className="homeAboutLearnMore applyBtnJob flipDet"
                        style={{
                          left: 100,
                          position: "absolute",
                          marginTop: 25,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setCareerDetails({
                            ...careerDetails,
                            position: job?.job_id,
                          });
                          nameRef?.current?.focus();
                        }}
                      >
                        <p className="learnMoreHomeAbt">Apply Now</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoDataFound
            message={"Oh oh ! Currently we are not having any openings"}
          />
        )}
      </div>
      <div className="serviceVdoCont" style={{ marginTop: -25 }}>
        <div
          className="servVdoLHS "
          style={{
            backgroundImage: `url(https://www.workitdaily.com/media-library/man-talks-to-his-boss-about-his-career-goals.jpg?id=30022265&width=2000&height=1500&quality=85&coordinates=0%2C0%2C100%2C0)`,
            backgroundColor: appColors.whiteColor,
            backgroundSize: "100% 100%",
          }}
        ></div>
        <div className="servVdoTestRHS">
          <div className="  servTestiCont">
            <div className="applyUpperLine"></div>
            <p
              className="whoWeHeadTxt"
              style={{
                marginTop: 10,
                color: appColors.whiteColor,
                fontSize: 22,
              }}
            >
              Apply to join our <p style={{ color: "crimson" }}>consulting</p>{" "}
              team
            </p>
            <form style={{ marginTop: 25 }}>
              <div className="contactConents">
                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Name
                  </p>
                  <input
                    ref={nameRef}
                    className="newLetterInput careerInput"
                    value={careerDetails.name}
                    onChange={(e) => {
                      setDetails(e.target.value, "name");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.name)}
                </div>

                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Email
                  </p>
                  <input
                    className="newLetterInput careerInput"
                    value={careerDetails.email}
                    onChange={(e) => {
                      setDetails(e.target.value, "email");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.email)}
                </div>
              </div>

              <div className="contactConents">
                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Phone
                  </p>
                  <input
                    className="newLetterInput careerInput"
                    value={careerDetails.mobile}
                    onChange={(e) => {
                      setDetails(e.target.value, "mobile");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.mobile)}
                </div>

                {jobList && Array.isArray(jobList) && (
                  <div className="carInputCont">
                    <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                      Apply position
                    </p>

                    <Select
                      onChange={(opt) => {
                        handleChange(opt);
                      }}
                      defaultValue={careerDetails?.position}
                      inputValue={
                        !formValidators.checkEmpty(careerDetails?.position)
                          ? [...jobList][
                              [...jobList].findIndex(
                                (el, i) => el.job_id === careerDetails?.position
                              )
                            ]?.job_title
                          : ""
                      }
                      options={jobList}
                      styles={customStyles}
                      placeholder="Choose position"
                    />
                    {formValidators.formErrors(validationErrors.position)}
                  </div>
                )}
              </div>

              <textarea
                className="newLetterInput careerInput careerInputDesc"
                style={{ marginTop: 25, minHeight: 100 }}
                placeholder="Your message here"
                value={careerDetails.message}
                onChange={(e) => {
                  setDetails(e.target.value, "message");
                }}
              />
              {formValidators.formErrors(validationErrors.message)}

              <div className="uploadBtnWrapper">
                <button
                  className="uploadFab"
                  id="uploadingFab"
                  onMouseOver={() => {
                    document.getElementById(
                      "uploadingFab"
                    ).style.backgroundColor = appColors.primaryOveryLay;
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "uploadingFab"
                    ).style.backgroundColor = appColors.secondaryColor;
                  }}
                >
                  <AttachFile style={{ fontSize: "15px" }} /> Your resume
                </button>
                {!formValidators.checkEmpty(careerDetails?.resume) ? (
                  <p className="resumeFileTxt">{careerDetails?.resume?.name}</p>
                ) : (
                  <p className="resumeFileTxt">No file chosen</p>
                )}
                {formValidators.formErrors(validationErrors.resume)}

                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => {
                    setValdiationErrors({ ...validationErrors, resume: false });
                    setDetails(e.target.files[0], "resume");
                  }}
                />
              </div>

              <button
                disabled={Boolean(loading)}
                className="formSubmitBtn"
                onClick={(e) => sendDetails(e)}
                style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
              >
                {loading ? "Submitting..." : "SUBMIT"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobsList: (requestData, onResponse) => {
      dispatch(getJobsList(requestData, onResponse));
    },
    applyJob: (requestData, onResponse) => {
      dispatch(applyJob(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(Career);
