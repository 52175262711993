import Endpoints from "../../api/Endpoints";
import RequestService from "../../api/HandleApiRequest";

export const getJobsList = (requestData, onResponse) => {
  console.log(requestData, "requestDatarequestData");
  if (requestData) {
    for (var value of requestData) {
      console.log(value);
    }
  }
  return (dispatch) => {
    RequestService.get(Endpoints.RECRUITMENT_LIST, requestData, (response) => {
      onResponse(response);
    });
  };
};
