import React from "react";

import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import appColors from "../utils/themes/colorThemes";
const NoDataFound = ({ message, customStyle }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...customStyle,
      }}
    >
      <SentimentSatisfiedIcon
        style={{
          color: appColors.primarySemiBold,
        }}
      />
      <p
        style={{
          color: appColors.secondaryExtraBold,
          fontWeight: "bold",
          marginLeft: 5,
        }}
      >
        {message ? message : "No data found"}
      </p>
    </div>
  );
};

export default NoDataFound;
