import React from "react";
import "../../styles/componentSass/header/_dashboardSideMenu.scss";

const DashboardSideHeader = (props) => {
  return (
    <div className="sideNavDashCont">
      <div className="sideNavDashInnCont">
        <h3 className="managementHeading">Management</h3>
        <ul className="navMenusDashCont">
          <li className="sideMenuDash">Chat Management</li>
          <ul className="navMenusDashInnListCont">
            <li className="sideMenuDash">Chat 1 Management</li>
            <li className="sideMenuDash">Chat 2 Management</li>
          </ul>
          <li className="sideMenuDash">CSE Management</li>
          {/* <li className="sideMenuDash"></li>
        <li className="sideMenuDash"></li>
        <li className="sideMenuDash"></li>
        <li className="sideMenuDash"></li>
        <li className="sideMenuDash"></li> */}
        </ul>
        <h3 className="managementHeading">Settings</h3>
        <ul className="navMenusDashCont">
          <li className="sideMenuDash">Chat Management</li>
          <ul className="navMenusDashInnListCont">
            <li className="sideMenuDash">Chat 1 Management</li>
            <li className="sideMenuDash">Chat 2 Management</li>
          </ul>
          <li className="sideMenuDash">CSE Management</li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSideHeader;
