import React, { useState } from "react";

import "../../styles/layoutSass/home/base.scss";
import "../../styles/layoutSass/about/base.scss";
import "../../styles/layoutSass/contactus/base.scss";
import {
  ChatBubbleOutline,
  CheckCircle,
  ChevronRight,
  LocationOnOutlined,
  Smartphone,
  WarningAmberOutlined,
} from "@mui/icons-material";
import appColors from "../../utils/themes/colorThemes";
import Toast from "../../components/Toast";
import formValidators from "../../utils/commonFunctions/formValidators";
import { connect } from "react-redux";
import { createRequestQuote } from "../../redux/actions/form.actions";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};

const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const ContactUs = (props) => {
  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);
  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      setLoading(true);
      props.createRequestQuote(
        requestData,
        (res) => {
          const response = res;
          if (Number(response.statusCode) === 200) {
            setResMsg({
              icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
              msg: response.message,
              bgColor: appColors.greenOverlay,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 3000);
            setValdiationErrors(initialErrors);
            setQuoteDetails(initialDetails);
            setLoading(false);
          } else {
            setResMsg({
              icon: (
                <WarningAmberOutlined style={{ color: appColors.whiteColor }} />
              ),
              msg: "Oops! Something went wrong!",
              bgColor: appColors.red,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 2000);
            setLoading(false);
          }
        },
        false,
        false
      );
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    console.log(errors, "Errors::");
    return formData;
  };
  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Contact Us"}</h1>

          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Contact Us
          </p>
        </div>
      </div>

      <div className="centerGrid80 contactConents">
        <div className="contactContLHS">
          <p
            className="whoWeContHeadTxt contactDetHeadTxt"
            style={{ color: appColors.blackColor }}
          >
            We're Happy to Discuss Your Project and Answer any Question
          </p>
          <div className="contactSocInfosCont">
            <LocationOnOutlined
              fontSize="small"
              className="contactSocInfosIcon"
            />
            <p className="whoWeContTxt" style={{ fontWeight: "bold" }}>
              Address{" "}
            </p>
          </div>
          <p className="contactGrayTxt" style={{ marginTop: 10 }}>
            77408 Satterfield Motorway Suite 469 Now{" "}
          </p>
          <p className="contactGrayTxt">Antonetto, BC K3L6P6 </p>
          <div className="contactSocInfosCont">
            <ChatBubbleOutline
              fontSize="small"
              className="contactSocInfosIcon"
            />
            <p className="whoWeContTxt" style={{ fontWeight: "bold" }}>
              Email{" "}
            </p>
          </div>
          <p className="contactGrayTxt" style={{ marginTop: 10 }}>
            info@avancerpi.com
          </p>
          <div className="contactSocInfosCont">
            <Smartphone fontSize="small" className="contactSocInfosIcon" />
            <p className="whoWeContTxt" style={{ fontWeight: "bold" }}>
              Contact number{" "}
            </p>
          </div>
          <p className="contactGrayTxt" style={{ marginTop: 10 }}>
            +91 9902693693
          </p>
        </div>
        <div className="contactContRHS">
          <div className="contactContRHSInnCont">
            <p className="whoWeHeadTxt" style={{ color: appColors.whiteColor }}>
              Let's Start
            </p>
            <p
              className="whoWeHead0Txt"
              style={{ color: appColors.whiteColor }}
            >
              The Conversation
            </p>
            <div className="contactConents">
              <input
                className="newLetterInput contactInput contactInputSemi"
                placeholder="Your Name *"
                value={quoteDetails.name}
                onChange={(e) => {
                  setDetails(e.target.value, "name");
                }}
              />

              <input
                className="newLetterInput contactInput contactInputSemi"
                placeholder="Your Email *"
                value={quoteDetails.email}
                onChange={(e) => {
                  setDetails(e.target.value, "email");
                }}
              />
            </div>
            <div className="contactConents">
              <div className="contactInputSemi">
                {formValidators.formErrors(validationErrors.name)}
              </div>
              <div className="contactInputSemi">
                {formValidators.formErrors(validationErrors.email)}
              </div>
            </div>

            <div className="contactConents">
              <input
                className="newLetterInput contactInput contactInputSemi"
                placeholder="Your Phone *"
                value={quoteDetails.mobile}
                onChange={(e) => {
                  setDetails(e.target.value, "mobile");
                }}
              />

              <input
                className="newLetterInput contactInput contactInputSemi"
                placeholder="Subject *"
                value={quoteDetails.sub}
                onChange={(e) => {
                  setDetails(e.target.value, "sub");
                }}
              />
            </div>
            <div className="contactConents">
              <div className="contactInputSemi">
                {formValidators.formErrors(validationErrors.mobile)}
              </div>
              <div className="contactInputSemi">
                {formValidators.formErrors(validationErrors.sub)}
              </div>
            </div>
            <textarea
              className="newLetterInput contactInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{
                backgroundColor: appColors.secondaryColor,
                cursor: Boolean(loading) ? "not-allowed" : "pointer",
              }}
            >
              {loading ? "Submitting..." : "SUBMIT"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(ContactUs);
