import React, { useState } from "react";
import DashboardHeader from "../components/header/DashboardHeader";
import DashboardSideHeader from "../components/header/DashboardSideHeader";
import "../styles/layoutSass/dashboard/base.scss";

const DashboardLayout = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [openDashMenu, setOpenDashMenu] = useState(true);
  React.useEffect(() => {
    let isActive = true;
    isActive && handleSize(window.innerWidth, window.innerHeight);
    isActive && window.addEventListener("resize", getCurrentScreenSize);
    return () => {
      isActive = false;
    };
  }, []);

  const getCurrentScreenSize = (e) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    handleSize(windowWidth, windowHeight);
  };
  const handleSize = (currentWidth, currentHeight) => {
    setCurrentWidth(currentWidth);

    if (currentWidth >= 700) {
      setIsMobileDevice(false);
    } else if (currentWidth < 700) {
      setIsMobileDevice(true);
    }
  };
  const toggleMenu = () => {
    setOpenDashMenu(!openDashMenu);
  };

  return (
    <div>
      <DashboardHeader openDashMenu={openDashMenu} toggleMenu={toggleMenu} />
      {openDashMenu && !isMobileDevice ? (
        <div className="dashWithSideMenuCont">
          <div className="dashSideCont1">
            <DashboardSideHeader />
          </div>
          <div className="dashSideCont2">
            <main style={{ marginBottom: "50px" }}>{children}</main>
          </div>
        </div>
      ) : (
        <div className="dashSideCont2" style={{ width: "100%" }}>
          <main style={{ marginBottom: "50px" }}>{children}</main>
        </div>
      )}
    </div>
  );
};

export default DashboardLayout;
