import React, { useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  CheckCircle,
  ChevronRight,
  Facebook,
  Instagram,
  LinkedIn,
  PlayCircleFilled,
  Twitter,
  WarningAmberOutlined,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const CloudAuto = (props) => {
  const [servicesList, setServicesList] = useState([
    {
      serviceName: "Service1",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.hci,
    },
    {
      serviceName: "Service2",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.workMig,
    },
    {
      serviceName: "Service3",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.disRec,
    },
    {
      serviceName: "Service4",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.cloudAuto,
    },

    {
      serviceName: "Service5",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.appModern,
    },
    {
      serviceName: "Service6",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.webDev,
    },
  ]);
  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Cloud Automation"}</h1>

          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont ">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">CLOUD</p>
          <p className="whoWeHeadTxt">Automation</p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            Cloud automation enables IT admins and Cloud admins to automate
            manual processes and speed up the delivery of infrastructure
            resources on a self-service basis, according to user or business
            demand. Cloud automation can also be used in the software
            development lifecycle for code testing, network diagnostics, data
            security, software-defined networking (SDN), or version control in
            DevOps teams.
            <br />
            <br />
            Cloud automation can also be implemented to support corporate WAN,
            VLAN, and SD-WAN deployments using software from VMware, Microsoft,
            or open source Linux developers
          </p>
        </div>
      </div>
      <div className="centerGrid80 serviceVdoCont" style={{ marginTop: 25 }}>
        <div className="servVdoLHS">
          <div className="servVdoLHSInnCont">
            <p className="servVdoTxt">
              The cloud automation is all about
              <br />
              <b> Reduction of error-prone processes,Cost savings.</b>
            </p>
            <p
              className="whoWeContTxt"
              style={{ fontWeight: "normal", color: appColors.whiteColor }}
            >
              Cloud automation of web servers for SaaS/PaaS applications can be
              scripted with command line utilities like Puppet, Jenkins, Git, or
              TravisCI.
            </p>
          </div>
        </div>
        <div className="servVdoRHS">
          <PlayCircleFilled
            style={{ fontSize: 40 }}
            className="vdoPlayIconServ"
          />
        </div>
      </div>
      <div className="serviceVdoCont">
        <div
          className="servVdoLHS "
          style={{
            backgroundImage: `url(${appImages.services.hciTestBanner})`,
            backgroundColor: appColors.whiteColor,
          }}
        ></div>
        <div className="servVdoTestRHS">
          <div className="  servTestiCont">
            <p
              className="whoWeHeadTxt servHeadTxt"
              style={{ marginTop: 10, color: appColors.whiteColor }}
            >
              What We Offer
            </p>
            <p
              className="whoWeContTxt"
              style={{ fontWeight: "normal", color: appColors.whiteColor }}
            >
              Cloud automation can also be used in the software development
              lifecycle for code testing, network diagnostics, data security,
              software-defined networking (SDN), or version control in DevOps
              teams.
            </p>
            <div className="serviceDescTabCont">
              <div className="serviceDescTabLHS">
                <p
                  className="whoWeHeadTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.whiteColor,
                    fontSize: 22,
                    textTransform: "uppercase",
                  }}
                >
                  Additional Information
                </p>
                <p
                  className="whoWeContTxt"
                  style={{ color: appColors.whiteColor }}
                >
                  We enable Auto Scaling, when traffic or demand of compute
                  resources spikes.
                </p>
              </div>
              <div className="serviceDescTabRHS">
                <p
                  className="whoWeHeadTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.whiteColor,
                    fontSize: 22,
                    textTransform: "uppercase",
                  }}
                >
                  REAL-TIME WORKLOAD DISTRIBUTION
                </p>
                <p
                  className="whoWeContTxt"
                  style={{ color: appColors.whiteColor }}
                >
                  No matter how much the load scales, applications and databases
                  run with high speed as the dedicated load balancers even out
                  the traffic on servers.
                </p>
              </div>
            </div>
            <div className="serviceDescTabCont">
              <div className="serviceDescTabLHS">
                <p
                  className="whoWeHeadTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.whiteColor,
                    fontSize: 22,
                    textTransform: "uppercase",
                  }}
                >
                  High Performance
                </p>
                <p
                  className="whoWeContTxt"
                  style={{ color: appColors.whiteColor }}
                >
                  Redundancy and high performance with storage controllers on
                  Dedicated Servers.
                </p>
              </div>
              <div className="serviceDescTabRHS">
                <p
                  className="whoWeHeadTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.whiteColor,
                    fontSize: 22,
                    textTransform: "uppercase",
                  }}
                >
                  Security
                </p>
                <p
                  className="whoWeContTxt"
                  style={{ color: appColors.whiteColor }}
                >
                  Protected, confidential, and sensitive information can be
                  stored on a private cloud while still leveraging resources of
                  the public cloud to run applications that rely on that data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="centerGrid80 aboutWhoWeAreCont "
        style={{ marginTop: 25 }}
      >
        <div className="whoWeHeadCont">
          <p className="whoWeContHeadTxt">Types of cloud automation ? </p>
          <p className="whoWeContHeadTxt" style={{ fontWeight: "normal" }}>
            There are two types of cloud automation.
          </p>
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            The first is support for corporate data center operations. The
            second is hosting for websites and mobile applications at scale.
            Public cloud hardware from AWS, Google Cloud, and Microsoft Azure
            can be used for either purpose. Code Stream, Cloud Assembly, and
            Service Broker all plugin to the VMware vCloud platform for DevOps
            and software development teams.
            <br />
            <br />
            In the second type, Cloud automation improves network traffic speeds
            through SDN and load balancing utilities, while also serving web and
            mobile applications to millions of page hits per day.
          </p>
          {/* <ul className="footerUl hciUl">
            <li className="listWithLogoStyle">
              <ChevronRight className="reqIcons" />
              <p className="subServiceName requiremntTxt">Scalability</p>
            </li>
            <li className="listWithLogoStyle">
              <ChevronRight className="reqIcons" />
              <p className="subServiceName requiremntTxt">Manageability</p>
            </li>
            <li className="listWithLogoStyle">
              <ChevronRight className="reqIcons" />
              <p className="subServiceName requiremntTxt">Recoverability</p>
            </li>
            <li className="listWithLogoStyle">
              <ChevronRight className="reqIcons" />
              <p className="subServiceName requiremntTxt">Performance</p>
            </li>
            <li className="listWithLogoStyle">
              <ChevronRight className="reqIcons" />
              <p className="subServiceName requiremntTxt">Security</p>
            </li>
          </ul> */}
        </div>
      </div>

      <div className="homeServicesCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR SERVICES </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Explore our other cloud solutions to meet
          <br />
          your business needs
        </p>
        <div
          className="servicesCardHome centerGrid60"
          style={{ border: "none" }}
        >
          {servicesList.map((service, i) => {
            return (
              <div className="servicesSingleSquare" key={i}>
                <div className="servImgNumCont">
                  <img
                    src={service.serviceIcon}
                    className="homeServCardImage"
                    alt="serviceImg"
                  />
                  <p className="serviceTitleHome servNo">0{i + 1}</p>
                </div>
                <p className="serviceTitleHome">{service.serviceName}</p>
                <p className="homeServDesc">{service.serviceDesc}</p>
              </div>
            );
          })}
        </div>
        {/* <div className="servicesCardHome centerGrid60">
          {servicesList.slice(3, 6).map((service, i) => {
            return (
              <div className="servicesSingleSquare" key={i}>
                <div className="servImgNumCont">
                  <img
                    src={service.serviceIcon}
                    className="homeServCardImage"
                    alt="serviceImg"
                  />
                  <p className="serviceTitleHome servNo">0{i + 1}</p>
                </div>
                <p className="serviceTitleHome">{service.serviceName}</p>
                <p className="homeServDesc">{service.serviceDesc}</p>
              </div>
            );
          })}
        </div> */}
      </div>
      <div
        className="centerGrid60 servFormCont"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next HCI Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt">+91 9902693693</p>
          <div className="socialIcContServOut">
            <div className="socialIcContServ ">
              <Instagram fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <Twitter fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <Facebook fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(CloudAuto);
