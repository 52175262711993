import React, { Fragment, useState } from "react";
import "../../styles/layoutSass/home/base.scss";
import appColors from "../../utils/themes/colorThemes";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import EventNoteIcon from "@mui/icons-material/EventNote";
import CommentIcon from "@mui/icons-material/Comment";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import appImages from "../../assets";
import Slider from "./LogoSlider";
import { useNavigate } from "react-router";

const Home = () => {
  const navigate = useNavigate();
  const mainSliders = [
    {
      id: 1,
      conent: "Challenges with",
      contentTitle: "Focusing current state and make analysis",
      // contentImg: appImages.homeImages.consult.currentStateAnalysis,
    },

    {
      id: 2,
      contentTitle: "Requirement Gathering and identifying constraints",
      conent: "Challenges with1",

      // contentImg: appImages.homeImages.consult.reqGathering,
    },
    {
      id: 3,
      contentTitle: "Mitigating risks and validating assumptions",
      conent: "Challenges with2",

      // contentImg: appImages.homeImages.consult.migitateAndValidate,
    },

    {
      id: 4,
      contentTitle: `Understanding customer objectives and satisfying their expectations`,
      conent: "Challenges with3",

      // contentImg: appImages.homeImages.consult.customerObjectives,
    },
  ];
  const [blogList, setBlogList] = useState([
    {
      blogName: "Evolving program Manage Office for modern",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "13 June 2021",
      blogComments: 5,
    },
    {
      blogName: "Design Decision with Bea Fietler Unstoppable",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "15 June 2021",
      blogComments: 3,
    },
    {
      blogName: "Pain free Workflow Issue Reporting Resolution",
      blogDesc: "printer took a galley of type and scrambled it to make a type",
      blogDate: "16 June 2021",
      blogComments: 15,
    },
  ]);

  const [servicesList, setServicesList] = useState([
    {
      serviceName: "HCI",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.hci,
      nav: "HCI",
    },
    {
      serviceName: "Workload Migration",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.workMig,
      nav: "WorkLoadMigration",
    },
    {
      serviceName: "Disaster Recovery",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.disRec,
      nav: "DisasterRecovery",
    },
    {
      serviceName: "Cloud Automation",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.cloudAuto,
      nav: "CloudAutomation",
    },

    {
      serviceName: "App Modernization",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.appModern,
      nav: "AppModernization",
    },
    {
      serviceName: "Web Development",
      serviceDesc:
        "Service took a galley of type and scrambled it to make a type",
      serviceIcon: appImages.icons.webDev,
      nav: "WebDevelopment",
    },
  ]);
  const aboutContent = [
    "Acheiving High Availability",
    "Security",
    "Manageability",
    "Scalability and",
    "Simplicity",
  ];

  const clientLogos = [
    appImages.logos.nutanixshort,
    appImages.logos.redhat,
    appImages.logos.vmware,
  ];
  const [sliderInd, setSliderInd] = useState(0);

  const [id, setId] = useState(0);

  React.useEffect(() => {
    console.log(setServicesList, setBlogList);
  }, []);
  const getId = () => {
    const randId = Math.random().toFixed(2);
    return randId;
  };

  return (
    <div className="mainContentSection">
      <div className="mainSliderHomeOuterCont">
        <div className="mainSliderHomeCont">
          {mainSliders.map((slider, i) => {
            return (
              <Fragment>
                {i === sliderInd && (
                  <div className="homeSliderLHS" id={id}>
                    <ArrowCircleLeftIcon
                      onClick={() => {
                        if (sliderInd !== 0) {
                          setId(getId());
                          setSliderInd(sliderInd - 1);
                        }
                      }}
                      className="arrowSlider"
                      style={{
                        color: "rgba(15, 15, 15, 0.685)",
                        fontSize: 30,
                      }}
                    />
                    <div className="homeTxtContainer">
                      <p className="homeHeader">
                        <p
                          className="homeHeaderTxt smallBoldTitles"
                          style={{
                            color: appColors.primaryColor,
                            fontSize: 16,
                          }}
                        >
                          <b>Information Technology Business </b>
                        </p>
                        <p className="challenges">
                          {slider.contentTitle}&nbsp;
                          {/* <b>
                            Innovative
                            <p
                              style={{
                                fontSize: "inherit",
                                color: appColors.primaryColor,
                              }}
                            >
                              ideas
                            </p>
                          </b> */}
                        </p>
                      </p>
                      <p className="homeHeaderTxt">
                        IT solutions has been established with the motive of
                        becoming one of the best
                      </p>
                      <div className="learnMoreBtnsHomeCont">
                        <div className="homeSliderBtn">
                          <ArrowRightAltIcon className="homeSlIon" />
                        </div>
                        <p className="homeSliderBtnTxt">
                          Discover our services
                        </p>
                      </div>
                    </div>
                    <ArrowCircleRightIcon
                      className="arrowSlider2"
                      style={{
                        color: "rgba(15, 15, 15, 0.685)",
                        fontSize: 30,
                      }}
                      onClick={() => {
                        if (sliderInd !== mainSliders.length - 1) {
                          setId(getId());
                          setSliderInd(sliderInd + 1);
                        }
                      }}
                    />
                    <div className="sliderDotCont">
                      {mainSliders.map((sliderDot, i) => {
                        return (
                          <div
                            onClick={() => setSliderInd(i)}
                            className="sliderDot"
                            style={{
                              backgroundColor:
                                sliderInd === i
                                  ? appColors.whiteColor
                                  : appColors.primaryColor,
                            }}
                            key={i}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="homeAboutCont centerGrid80">
        <div className="homeAboutImgCont">
          <img
            className="homeAboutImg"
            alt="avancerAboutImg"
            src={appImages.homeImages.Aboutus}
          />
        </div>
        <div className="homeAboutContentsCont">
          <div className="homeAboutLine"></div>
          <p className="homeAboutTxt">About Us</p>
          <div className="aboutDetailsHomeCont">
            <p className="aboutHeadingHome">
              We take your business to
              <br /> next level
            </p>
            <span>
              <p className="aboutHomeContent" style={{ fontSize: 18 }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <ul className="homeAboutList">
                {aboutContent.map((content, i) => {
                  return (
                    <li className="aboutInnList" key={i}>
                      <CheckCircleIcon
                        className="aboutListIcon"
                        style={{ fontSize: 10 }}
                      />
                      <p className="aboutInnContent">{content}</p>
                    </li>
                  );
                })}
              </ul>
              <div className="homeAboutLearnMore">
                <p className="learnMoreHomeAbt">Learn More</p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="homeTagLinesCont">
        <div className="homeConsultInnCont centerGrid80">
          <p className="headTxtSmall smallBoldTitles">
            <b>Core Services </b>
          </p>
          <p
            className="aboutHeadingHome textAlignCenter"
            style={{
              marginTop: 20,
              marginBottom: 20,
              color: appColors.whiteColor,
            }}
          >
            We take your business to
            <br /> next level
          </p>

          <div className="tagLineBoxCont">
            <div className="tagLineSquare">
              <img alt="avancerImg" src={appImages.icons.consult} />
              <p
                style={{ color: appColors.blackColor }}
                className="aboutHeadingHome textAlignCenter tagHead"
              >
                Consult
              </p>
              <p className="tagContent">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="tagLineSquare">
              <img
                alt="avancerImg"
                style={{
                  marginLeft: 10,
                }}
                src={appImages.icons.design}
              />
              <p
                style={{ color: appColors.blackColor }}
                className="aboutHeadingHome textAlignCenter tagHead"
              >
                Design
              </p>
              <p className="tagContent">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>

            <div className="tagLineSquare">
              <img alt="avancerImg" src={appImages.icons.build} />
              <p
                style={{ color: appColors.blackColor }}
                className="aboutHeadingHome textAlignCenter tagHead"
              >
                Build
              </p>
              <p className="tagContent">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="tagLineSquare">
              <img alt="avancerImg" src={appImages.icons.train} />
              <p
                style={{ color: appColors.blackColor }}
                className="aboutHeadingHome textAlignCenter tagHead"
              >
                Train
              </p>
              <p className="tagContent">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="homeServicesCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR SERVICES </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Best Services
          <br />
          for you
        </p>
        <div
          className="servicesCardHome centerGrid60"
          style={{ border: "none" }}
        >
          {servicesList.map((service, i) => {
            return (
              <div
                className="servicesSingleSquare"
                key={i}
                onClick={() => navigate(service.nav)}
              >
                <div className="careerInnrSqrCard">
                  <div className="servImgNumCont">
                    <img
                      src={service.serviceIcon}
                      className="homeServCardImage"
                      alt="serviceImg"
                    />
                    <p className="serviceTitleHome servNo">0{i + 1}</p>
                  </div>
                  <p className="serviceTitleHome">{service.serviceName}</p>
                  <p className="homeServDesc">{service.serviceDesc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="homeTestimonialsCont">
        <div className="homeTestimonialsInnCont centerGrid80">
          <div className="homeTestiHeadCont">
            <p className="testiTxtSmall">
              <b>TESTIMONIALS</b>
            </p>
            <p className="testiHeaderTxt">
              <b>What Our Clients &nbsp;</b>
              <p
                style={{
                  fontSize: "inherit",
                  marginTop: 5,
                }}
              >
                Say about us
              </p>
            </p>
            <div className="testiQuotes">
              <p>”</p>
              <div className="purpleCircleTest"></div>
            </div>
          </div>
          <div className="homeTestiContCont">
            <p
              className="testiHomeContent"
              style={{
                color: appColors.whiteColor,
                marginTop: 0,
                lineHeight: 1.25,
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
            <p className="smallBoldTitles testiAuthorName">Daniel Patrick</p>
            <p className="testiAuthorPosition">CEO & founder of Google</p>

            <div className="arrowsIconsCont">
              <ArrowBackIcon className="testiArrowIcons" />
              <ArrowForwardIcon className="testiArrowIcons" />
            </div>
          </div>
        </div>
      </div>
      <div className="homeBlogsCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR BLOGS </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Trending Business Consulting
          <br />
          Articles {"&"} Tips
        </p>
        <div className="blogCardsContHome centerGrid60">
          {blogList.map((blog, i) => {
            return (
              <div className="homeBlogCard" key={i}>
                <img
                  src="https://www.jobboardfinder.com/news/wp-content/uploads/2021/07/team.jpg"
                  className="blogCardImageHome"
                  alt="blogImg"
                />
                <p className="blogTitleHome">{blog.blogName}</p>
                <p className="homeBlogDesc">{blog.blogDesc}</p>
                <div className="homeBlogTimeComCont">
                  <EventNoteIcon
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 3.5 }}>
                    {blog.blogDate}
                  </p>
                  <CommentIcon
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 2.5 }}>
                    {blog.blogComments}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="clientSliderContHome">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR CLIENTS </b>
        </p>
        <p
          style={{ marginTop: 10 }}
          className="aboutHeadingHome textAlignCenter"
        >
          Committed to Helping
          <br />
          Our Clients Succeed
        </p>

        <div className="customMarquee">
          <div className="marqueeInner">
            <Slider data={clientLogos} />
            {/* <marquee>
              {clientLogos.map((client, i) => {
                return (
                  <img
                    key={i}
                    className="logoImg"
                    src={client}
                    alt="avancerClients"
                  />
                );
              })}
            </marquee> */}
          </div>
        </div>
      </div>
      <div className="homeFormsCont">
        <p className="headTxtSmall smallBoldTitles" style={{ marginTop: -100 }}>
          <b>JOIN US</b>
        </p>

        <div className="blogCardsContHome centerGrid60">
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Let's Ready to Get
                <br /> Work Together
              </p>
              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">Join Now</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Request to call back <br />
                We Will Call Back
              </p>

              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">Get a quote</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Build a Better Career <br />
                With Avancer
              </p>
              <div className="homeFormLearnMore">
                <p className="learnMoreHomeAbt">View jobs</p>
                <DoubleArrowIcon
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
