import Endpoints from "../../api/Endpoints";
import RequestService from "../../api/HandleApiRequest";

export const createRequestQuote = (requestData, onResponse) => {
  console.log(requestData, "requestDatarequestData");
  if (requestData) {
    for (var value of requestData) {
      console.log(value);
    }
  }
  return (dispatch) => {
    RequestService.post(
      Endpoints.CREATE_REQUEST_QUOTE,
      requestData,
      (response) => {
        onResponse(response);
      }
    );
  };
};

export const applyJob = (requestData, onResponse) => {
  console.log(requestData, "requestDatarequestData");
  if (requestData) {
    for (var value of requestData) {
      console.log(value);
    }
  }
  return (dispatch) => {
    RequestService.post(
      Endpoints.APPLY_RECRUITMENT,
      requestData,
      (response) => {
        onResponse(response);
      }
    );
  };
};
